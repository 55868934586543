import { RECOMMENDED_MANAGED_LOGO_SIZE } from '$src/constants.js'

function addSortRank(accounts) {
    accounts.forEach((i) => {
        if (i.preferred) {
            i.sortRank = 0
        } else if (i.recovery) {
            i.sortRank = 1
        } else if (i.verifiedAt && i.slug !== 'instagram' && !i.linkedAccount) {
            i.sortRank = 2
        } else if (i.verifiedAt && i.slug === 'instagram') {
            i.sortRank = 3
        } else if (i.linkedAccount) {
            i.sortRank = 4
        } else if (!i.verifiedAt) {
            i.sortRank = 5
        }
    })
    return accounts
}

function addSortLabel(accounts) {
    accounts.forEach((account) => {
        let prefix = null
        if (['email', 'phone'].includes(account.slug)) {
            prefix = 'zz_' + account.slug //sort to last
        } else {
            prefix = account.slug
        }
        if (account.linkedAccount) {
            prefix = prefix + '_' + account.linkedAccount
        }
        account.sortLabel = prefix + '_' + account.user_name
    })
    return accounts
}

function accounts(a, b) {
    if (a.sortRank > b.sortRank) return 1
    if (b.sortRank > a.sortRank) return -1

    //same rank -- sort by label
    return a.sortLabel?.localeCompare(b.sortLabel)
}

function devices(currentDevice, a, b) {
    if (a.id === currentDevice && b.id !== currentDevice) return -1
    if (a.id !== currentDevice && b.id === currentDevice) return 1
    if (a.lastAccess && b.lastAccess) {
        return Date.parse(b.lastAccess.at) - Date.parse(a.lastAccess.at)
    } else if (!a.lastAccess && !b.lastAccess) {
        return Date.parse(b.createdAt) - Date.parse(a.createdAt)
    } else if (!a.lastAccess && b.lastAccess) {
        return Date.parse(b.lastAccess.at) - Date.parse(a.createdAt)
    } else if (a.lastAccess && !b.lastAccess) {
        return Date.parse(b.createdAt) - Date.parse(a.lastAccess.at)
    }
    return 0
}

function apps(a, b) {
    if (a.lastRelease && b.lastRelease) {
        return Date.parse(b.lastRelease.at) - Date.parse(a.lastRelease.at)
    } else if (!a.lastRelease && !b.lastRelease) {
        return Date.parse(b.firstRelease.at) - Date.parse(a.firstRelease.at)
    } else if (!a.lastRelease && b.lastRelease) {
        return Date.parse(b.lastRelease.at) - Date.parse(a.firstRelease.at)
    } else if (a.lastRelease && !b.lastRelease) {
        return Date.parse(b.firstRelease.at) - Date.parse(a.lastRelease.at)
    }
    return 0
}

function managedLogos(a, b) {
    const isASvg = a.mimeType?.includes('svg')
    const isBSvg = b.mimeType?.includes('svg')

    // SVGs always on top
    if (isASvg && !isBSvg) return -1
    if (!isASvg && isBSvg) return 1

    const largestA = Math.max(a.width, a.height)
    const largestB = Math.max(b.width, b.height)

    // Sort by largestA or largestB values >= 36 in ascending order
    if (
        largestA >= RECOMMENDED_MANAGED_LOGO_SIZE &&
        largestB >= RECOMMENDED_MANAGED_LOGO_SIZE
    ) {
        return largestA - largestB
    }

    // Sort by largestA or largestB values < RECOMMENDED_MANAGED_LOGO_SIZE in descending order
    if (
        largestA < RECOMMENDED_MANAGED_LOGO_SIZE &&
        largestB < RECOMMENDED_MANAGED_LOGO_SIZE
    ) {
        return largestB - largestA
    }

    // Ensure that >=RECOMMENDED_MANAGED_LOGO_SIZE values are after <RECOMMENDED_MANAGED_LOGO_SIZE values
    return largestA >= RECOMMENDED_MANAGED_LOGO_SIZE ? -1 : 1
}

export { addSortRank, addSortLabel, accounts, devices, apps, managedLogos }
